import React from 'react'
import styled from 'styled-components'

/* Styles */
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px 10px;
  @media (min-width: 768px) {
    padding: 20px 20px;
  }
  @media (min-width: 1024px) {
    padding: 50px 20px;
  }
`
const CommentContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`
const Paragraph = styled.p`
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  padding: 15px 10px;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 300;
    max-width: 900px;
    width: 100%;
    line-height: 1.7;
  }
`
const CiteContainer = styled.div`
  text-align: right;
  margin-right: 10px;
  font-size: 16px;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 18px;
    max-width: 900px;
  }
`
const SectionComment = () => {
  return (
    <Section>
      <CommentContainer>
      <Paragraph>
        “MySchedulr is an application that business owners can use to manage and
        organize the scheduling of services offered, along with all the
        appointments/classes that have been booked by their customers.”
      </Paragraph>
      <CiteContainer>
        <cite>-Sierra E. Project Manager</cite>
      </CiteContainer>
      </CommentContainer>
    </Section>
  )
}

export default SectionComment
