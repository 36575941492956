/**
 *
 */

import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile, faEnvelope, faCalendarTimes } from '@fortawesome/free-solid-svg-icons'

/* Assets */
import Provide from '../../images/myschedulr-image.svg'
import data from './data.json'

/* Styles */
const StyledDiv = styled.div`
width: 100%;
  height: 100%;
  background-color: #edf5f7;
`
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 768px) {
    height: 100vh;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
`
const Figure = styled.figure`
  width: 100%;
  margin: 10px;
  max-width: 700px;
`
const Image = styled.img`
  width: 100%;
`
const ContentContainer = styled.div`
  padding: 0px 10px;
`
const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 12px;
`
const Paragraph = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  max-width: 500px;
`
const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0px;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`
const Subtitle = styled.h4`
  margin: 0px;
  font-size: 22px;
  font-weight: 500;
`
const DescriptionContainer = styled.div`
  margin-left: 10px;
`
const DescriptionParagraph = styled.p`
  margin: 0px;
  margin-top: 3px;  
  max-width: 450px;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  color: #213053;
`
const SectionZoomIntegrations = () => {
  return (
    <StyledDiv>
    <Section>
      
      <Figure>
        <Image src={Provide} alt="Provide" />
      </Figure>
      <ContentContainer>
        <Title>MySchedulr is made for you.</Title>
        <Paragraph>
          Inside of MySchedulr business owners are provided multiple ways to
          track upcoming appointments and available team members.
        </Paragraph>
        {data.map((item) => (
          <DetailsContainer>
            <FontAwesomeIcon
              className="fa-2x"
              icon={
                item.id == 1
                  ? faMobile
                  : item.id == 2
                  ? faEnvelope
                  : faCalendarTimes
              }
              color="#0076DF"
            />
            <DescriptionContainer>
              <Subtitle>{item.title}</Subtitle>
              <DescriptionParagraph>{item.description}</DescriptionParagraph>
            </DescriptionContainer>
          </DetailsContainer>
        ))}
      </ContentContainer>
    </Section>
    </StyledDiv>
  );
}

export default SectionZoomIntegrations
