import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faHistory, faLaptop } from '@fortawesome/free-solid-svg-icons'

import {
  Section,
  ContentContainer,
  Image,
  Title,
  Figure,
  Paragraph,
  DetailsContainer,
  DescriptionContainer,
  Subtitle,
  DescriptionParagraph,
} from './section-zoom-integration.styles'

/* Assets */
import ZoomIntegrations from '../../images/ZoomIntegrations.webp'
import data from './data.json'




const SectionZoomIntegrations = () => {
  return (
    <Section>
      <Figure>
        <Image src={ZoomIntegrations} alt="Zoom Integrations" />
      </Figure>
      <ContentContainer>
        <Title>Schedule Zoom meetings</Title>
        <Paragraph>
          Our Zoom integration makes it easy to start virtual meetings,
          webinars, and events when a client schedules an appointment.{" "}
        </Paragraph>
        {data.map((item) => (
          <DetailsContainer>
            <FontAwesomeIcon className="fa-2x" icon={item.id == 1 ? faVideo : item.id == 2 ? faHistory : faLaptop} color="#0076DF" />
            <DescriptionContainer>
              <Subtitle>{item.title}</Subtitle>
              <DescriptionParagraph>
                {item.description}
              </DescriptionParagraph>
            </DescriptionContainer>
          </DetailsContainer>
        ))}
      </ContentContainer>
    </Section>
  );
}

export default SectionZoomIntegrations
