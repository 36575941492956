import React from 'react'
import { navigate } from "gatsby"

/* Components */
import Header from '../../components/Header'


/* Assets */
import Hero from '../../images/Provide.webp'
import Points from '../../images/Points.webp'
// import HeroBooking from '../../images/hero-booking.svg'

import {
  Section, 
  ContentContainer, 
  TitleAndParagraphContainer, 
  Title, 
  Paragraph, 
  StyledButton, 
  Figure, 
  Image,
  ImagePoints,
  ImagePointsContainer} from './section-hero.styles'

const SectionHero = () => {
  return (
    <Section>
      <Header />
      <ContentContainer>
        <TitleAndParagraphContainer>
          <Title>Scheduling,</Title>
          <Title>Made Simple</Title>
          <Paragraph>
            We have created an online scheduling solution that easily integrates
            with your website and domain. Manage your own bookings page that
            will give your clients a simple view of your availability so they
            can schedule their appointments with ease!
          </Paragraph>          
          <StyledButton onClick={()=>navigate('https://wordpress.org/plugins/myschedulr/')}>Get started for free</StyledButton>          
        </TitleAndParagraphContainer>
        <Figure>
          <Image src={Hero} />
        </Figure>        
      </ContentContainer>
      <ImagePointsContainer>
        <ImagePoints src={Points} />
      </ImagePointsContainer>
    </Section>
  )
}

export default SectionHero
