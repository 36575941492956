/**
 *
 */

import React from 'react'
import styled from 'styled-components'
import { navigate } from "gatsby"
/* Assets */
import Logo from '../../images/Logo.webp'

/* Components */
import Button from '../Button'

/* Styles */
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-top: 12px;
  
`

const Figure = styled.figure`
  width: 200px;
  padding-left: 10px;
  @media (min-width: 1024px) {
    padding-left: 72px;
  }
`;
const Img = styled.img`
  width: 100%;
`

const StyledButton = styled.button`
  background-color: transparent;
  color: #0076df;
  border: none;
  border-radius: 4px;
  border: 1px solid #0076df;
  padding: 12px 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  &:hover {
    color: #fff;
    background-color: #0076df;
  }
  @media (min-width: 1024px) {
    margin-right: 40px;
  }
`

const Head = () => {
  return (
    <Header>
      <Figure>
        <Img src={Logo} alt="FOS Bookings" />
      </Figure>      
        <StyledButton onClick={()=>navigate('https://wordpress.org/plugins/myschedulr/')}>Create free account</StyledButton>              
    </Header>
  )
}

export default Head
