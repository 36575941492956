import React from 'react'
import styled from 'styled-components'

import Button from '../../components/Button'

export const Section = styled.section`
  width: 100%;
  background-color: #edf5f7;
  @media (min-width: 768px) {
    height: 100%;
  }
`


export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  max-width: 1400px;
//   margin: 0 auto;
  padding: 0 40px;
  margin-top: 70px;
 
  @media (min-width: 768px) {
    justify-content: space-around;
    align-items: center; 
    margin-top: 70px;
  }
  @media (min-width: 1024px) { 
    flex-direction: row;   
    padding: 0px 40px;
    margin: 0 auto;
  }
`

export const TitleAndParagraphContainer = styled.div`
  max-width: 650px;
`
export const Title = styled.h2`
  color: #13254a;
  font-size: 60px;
  font-weight: 600;
  margin: 0 0 10px 0;
`
export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #213053;
`
export const StyledButton = styled(Button)`
  margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    width: 200px;
    height: 70px;
    border-radius: 20px;
    box-shadow: 0 26px 46px rgb(98 155 241 / 50%);
`
export const Figure = styled.figure`
  width: 100%;
  max-width: 600px;
  margin : auto;

`
export const ImagePointsContainer = styled.figure`
width: 100%;
max-width: 1400px;
margin : auto;

`
export const Image = styled.img`
  width: 100%;
`
export const ImagePoints = styled.img`
  width: 100%;
  max-width: 100px;
`


