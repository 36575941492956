
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 768px) {
    height: 100vh;
    flex-direction: row;
    justify-content: space-around;
  }
`
export const Figure = styled.figure`
  width: 100%;
  margin: 10px;
  max-width: 700px;
`
export const Image = styled.img`
  width: 100%;
`
export const ContentContainer = styled.div`
  padding: 0px 10px;
`
export const Title = styled.h2`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 12px;
`
export const Paragraph = styled.p`
  font-size: 20px;
  text-align: left;
  max-width: 500px;
  font-weight: 400;
`
export const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0px;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`
export const Subtitle = styled.h4`
  margin: 0px;
  font-size: 22px;
  font-weight: 500;
`
export const DescriptionContainer = styled.div`
  margin-left: 10px;
`
export const DescriptionParagraph = styled.p`
  margin: 0px;
  margin-top: 3px;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  color: #213053;
  max-width: 450px;
`;